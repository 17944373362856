window.addEventListener('load', () => {
  // Share on mobile
  const shareData = {
    title: document.querySelector('h1').innerText,
    url: window.location.href,
  };

  const btn = document.querySelector('#shareButton');
  const resultPara = document.querySelector('.result');

  if(btn) {
    btn.addEventListener('click', () => {
      try {
        navigator.share(shareData).then(() => {});
        resultPara.textContent = 'The page has been shared';
      } catch (err) {
        resultPara.textContent = `Error: ${err}`;
      }
    });
  }
});
