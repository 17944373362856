import Swiper from 'swiper/bundle';

function initSwiper() {
  new Swiper('.home-be-inspired-swiper', {
    slidesPerView: 1.2,
    spaceBetween: 20,
    breakpoints: {
      768: {
        slidesPerView: 2.2,
        spaceBetween: 30,
      },
    },
  });

  new Swiper('.home-last-news-swiper', {
    slidesPerView: 1.2,
    spaceBetween: 20,
    breakpoints: {
      768: {
        slidesPerView: 2.2,
        spaceBetween: 30,
      },
    },
  });

  new Swiper('.archive-footer-cards-container-swiper', {
    slidesPerView: 1.2,
    spaceBetween: 20,
    breakpoints: {
      768: {
        slidesPerView: 2.2,
        spaceBetween: 30,
      },
    },
  });

  new Swiper(".archive-footer-cards-container-swiper", {
    slidesPerView: 1.2,
    spaceBetween: 20,
    breakpoints: {
      768: {
        slidesPerView: 2.2,
        spaceBetween: 30,
      },
    },
  });
}

initSwiper();
