import $ from 'jquery';

$(function () {
  if ($('body').hasClass('post-type-archive-event')) {
    $(document).on('click', '.event-reset-filters', function () {
      $('#event-filters-form').trigger('reset');
    });
  }
});

document.addEventListener('DOMContentLoaded', () => {
  if (window.location.href.indexOf("events") > -1) {
    const mapButton = document.getElementById('pills-map-tab');
    const listingButton = document.getElementById('pills-listing-tab');
    const mapPane = document.getElementById('pills-map');
    const listingPane = document.getElementById('pills-listing');
    const activeTab = localStorage.getItem('activeTab') || 'pills-map-tab';

    if (activeTab === 'pills-listing-tab') {
      mapButton.classList.remove('active');
      mapButton.setAttribute('aria-selected', 'false');
      mapPane.classList.remove('active', 'show');

      listingButton.classList.add('active');
      listingButton.setAttribute('aria-selected', 'true');
      listingPane.classList.add('active', 'show');
    } else {
      mapButton.classList.add('active');
      mapButton.setAttribute('aria-selected', 'true');
      mapPane.classList.add('active', 'show');

      listingButton.classList.remove('active');
      listingButton.setAttribute('aria-selected', 'false');
      listingPane.classList.remove('active', 'show');
    }

    mapButton.addEventListener('click', () => {
      localStorage.setItem('activeTab', 'pills-map-tab');
      mapButton.classList.add('active');
      mapButton.setAttribute('aria-selected', 'true');
      mapPane.classList.add('active', 'show');

      listingButton.classList.remove('active');
      listingButton.setAttribute('aria-selected', 'false');
      listingPane.classList.remove('active', 'show');
    });

    listingButton.addEventListener('click', () => {
      localStorage.setItem('activeTab', 'pills-listing-tab');
      listingButton.classList.add('active');
      listingButton.setAttribute('aria-selected', 'true');
      listingPane.classList.add('active', 'show');

      mapButton.classList.remove('active');
      mapButton.setAttribute('aria-selected', 'false');
      mapPane.classList.remove('active', 'show');
    });
  }
});
