/* eslint-disable  no-undef */

(function(d, s) {
  var t = d.getElementsByTagName(s)[0], e = d.createElement(s);
  e.async = true; e.src = "https://tag.aticdn.net/piano-analytics.js";
  t.parentNode.insertBefore(e, t);
})(document, "script");

window._axcb = window._axcb || [];
window._axcb.push(function (axeptio) {
  axeptio.on("cookies:complete", function (choices) {
    if (choices.at_internet) {
      loadPianoAnalytics();
    }
  });
});

function loadPianoAnalytics() {
  pa.setConfigurations({
    site: 628482,
    collectDomain: 'pwzrqws.pa-cd.com',
  });
  pa.sendEvent('page.display', {
    page: getPageTitle(),
  });
}

function getPageTitle() {
  const title = $('h1');
  return title.text().replace(/\s+/g, ' ').trim();
}

