import $ from 'jquery';

$(function () {
  if ($(window).width() > 992) {
    $(document).on('click', '#copyPasteButton', function() {
      let url = $('#copyBtnUrl').val();
      let msgSuccess = $('.share-copied-success');

      navigator.clipboard.writeText(url);
      msgSuccess.css('display', 'block');

      // Hide msg after 2s
      setTimeout(function () {
        msgSuccess.css('display', 'none');
      }, 1500);
    });
  }
});
