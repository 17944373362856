/* global google */
import $ from 'jquery';

function getCountryData(place) {
  for (let i = 0; i < place.address_components.length; i++) {
    const component = place.address_components[i];
    if (component.types.includes('country')) {
      return [
        component.short_name,
        component.long_name,
      ];
    }
  }

  return null;
}

function getPostalCodeData(place) {
  for (let i = 0; i < place.address_components.length; i++) {
    const component = place.address_components[i];
    if (component.types.includes('postal_code')) {
      return component.long_name;
    }
  }

  return null;
}

function getCityData(place) {
  for (let i = 0; i < place.address_components.length; i++) {
    const component = place.address_components[i];
    if (component.types.includes('locality') || component.types.includes('postal_town')) {
      return component.long_name;
    }
  }

  return null;
}

let event_address_valid = false;
let goodies_address_valid = false;

const french_countries = ['FR', 'GP', 'MQ', 'GF', 'RE', 'YT', 'PM', 'WF', 'PF', 'NC', 'BL', 'MF'];

function initAddressAutocomplete() {
  const element = document.getElementById('crb_event_address');
  if (!element) {
    return;
  }

  const autocomplete = new google.maps.places.Autocomplete(
    element,
    { types: ['address'] }
  );

  // Set up the event listener when a place is selected.
  autocomplete.addListener('place_changed', function () {
    const place = autocomplete.getPlace();
    const country = getCountryData(place);
    const postal_code = getPostalCodeData(place);
    const city = getCityData(place);
    event_address_valid = true;

    if (french_countries.includes(country[0])) {
      // If the country is FR or DOM TOM, display
      $('#goodies-wrapper').removeClass('event-form-hidden');
    } else {
      $('#goodies-wrapper').addClass('event-form-hidden');
    }

    $('input[name=event_address_country]').val(country[0]);
    $('input[name=event_address_country_name]').val(country[1]);
    $('input[name=event_postal_code]').val(postal_code);
    $('input[name=event_city]').val(city);
    $('input[name=event_address_lat]').val(place.geometry.location.lat);
    $('input[name=event_address_lng]').val(place.geometry.location.lng);

    // Trigger custom event to toggle Next button
    $('#crb_event_address').trigger("after_place_changed");
  });
}

function initGoodiesAutocomplete() {
  const element = document.getElementById('crb_event_goodies_delivery');
  if (!element) {
    return;
  }

  const autocomplete = new google.maps.places.Autocomplete(
    element,
    { types: ['address'], componentRestrictions: { country: french_countries } }
  );

  // Set up the event listener when a place is selected.
  autocomplete.addListener('place_changed', function () {
    const place = autocomplete.getPlace();
    const postal_code = getPostalCodeData(place);
    const city = getCityData(place);
    goodies_address_valid = true;

    $('input[name=crb_event_goodies_delivery_postal_code]').val(postal_code);
    $('input[name=crb_event_goodies_delivery_city]').val(city);

    // Trigger custom event to toggle Next button
    $('#crb_event_goodies_delivery').trigger("after_place_changed");
  });
}

function setDisplayHolderFields(display) {
  const holderFields = $('#crb_event_erasmus_plus_result_url, #crb_event_erasmus_plus_oid_code, #crb_event_ed_number').parent();
  if (display) holderFields.show();
  else holderFields.hide();
}

function setDisplayHolderJustifyField(display) {
  const justify = $('#crb_event_project_holder_justify');
  if (display) justify.show();
  else justify.hide();
}

function initAutocomplete() {
  initAddressAutocomplete();
  initGoodiesAutocomplete();
}

window.initAutocomplete = initAutocomplete;

$(function () {
  function removeActiveStep() {
    $('.event-form-active-step').removeClass('event-form-active-step');
  }

  function setActiveStep(step) {
    $('#active-step-' + step).addClass('event-form-active-step');
  }

  function validateStep(step) {
    const fieldset = document.querySelector(`#section-${step}-fieldset`);
    const need_goodies_address = $('#crb_event_goodies_yes').is(':checked');

    return !fieldset ||
      (
        fieldset.querySelectorAll('.form-control:invalid').length === 0 &&
        fieldset.querySelectorAll('.form-check-input:invalid').length === 0 &&
        event_address_valid &&
        (
          (need_goodies_address && goodies_address_valid !== false) ||
          !need_goodies_address
        ) &&
        checkboxFormGridValid(fieldset));
  }

  function checkboxFormGridValid(fieldset) {
    // cast to array
    let formGrids = [...fieldset.querySelectorAll('.form-event-grid')];

    // if every checkbox group has at least one checkbox ticked, it is correct
    return formGrids.every((e) => {
      return e.querySelectorAll('input[type="checkbox"]:checked').length !== 0;
    });
  }

  function toggleNextButton() {
    if (validateStep(currentSection)) {
      next.prop('disabled', false);
    } else {
      next.prop('disabled', true);
    }
  }

  function showSection(section) {
    faq.hide();
    switch (section) {
      case 1:
        removeActiveStep();
        setActiveStep(1);

        submit.hide();
        prev.hide();
        next.show().text('Start form');
        break;

      case 2:
        removeActiveStep();
        setActiveStep(2);

        submit.hide();
        prev.show();
        next.show().text('Next step');
        break;

      case 3:
        removeActiveStep();
        setActiveStep(3);

        submit.show();
        prev.show();
        next.hide();
        break;

      case 4:
        removeActiveStep();
        setActiveStep(4);

        submit.remove();
        prev.remove();
        next.remove();
        faq.show();
        break;

      default:
        break;
    }

    $('.form-section').addClass('event-form-hidden');
    $('.event-form-subtitle').addClass('event-form-hidden');

    $('#section-' + section).removeClass('event-form-hidden');
    $('#subtitle-' + section).removeClass('event-form-hidden');
  }

  // Dom elements
  const prev = $('#form-button-prev');
  const next = $('#form-button-next');
  const submit = $('#form-button-submit');
  const faq = $('#form-button-faq');

  let currentSection = 1;
  showSection(currentSection);
  setDisplayHolderFields(false);
  setDisplayHolderJustifyField(false);

  // Event listeners
  $(document).on('click', '#form-button-next', function (e) {
    e.preventDefault();
    document.getElementById('event-form-create').scrollIntoView();

    if (!validateStep(currentSection)) {
      // Display error message
      return;
    }

    if (currentSection < 4) {
      currentSection++;
      showSection(currentSection);
      toggleNextButton();
    }
  });

  $(document).on('click', '#form-button-prev', function (e) {
    e.preventDefault();

    if (currentSection < 4) {
      currentSection--;
      showSection(currentSection);
      toggleNextButton();
    }
  });

  $(document).on('change keyup', '#event-form-create .form-control, #event-form-create .form-check-input', function () {
    toggleNextButton();
  });

  $(document).on('change keyup', '#event-form-create #crb_event_address', function () {
    event_address_valid = false;
    $(this).addClass('form-control-warning');
    toggleNextButton();
  });

  $(document).on('change keyup', '#event-form-create #crb_event_goodies_delivery', function () {
    goodies_address_valid = false;
    $(this).addClass('form-control-warning');
    toggleNextButton();
  });

  $(document).on('after_place_changed', '#event-form-create #crb_event_address,#event-form-create #crb_event_goodies_delivery', function () {
    $(this).removeClass('form-control-warning');
    toggleNextButton();
  });

  $(document).on('change', '[name="crb_event_goodies"]', function () {
    if ($(this).val() === 'yes') {
      $('#goodies-address-wrapper').removeClass('event-form-hidden');
    } else {
      $('#goodies-address-wrapper').addClass('event-form-hidden');
    }
  });

  $(document).on('change', '[name="crb_event_project_holder"]', function () {
    const holder = $(this).val() === 'yes';
    setDisplayHolderFields(holder);
    setDisplayHolderJustifyField(!holder);
  });

  $(document).on('submit', '#event-form-create', function (e) {
    e.preventDefault();

    $.ajax({
      url: '/wp-admin/admin-ajax.php',
      type: 'POST',
      processData: false,
      contentType: false,
      data: new FormData(this),
      beforeSend: function () {
        $('#form-button-submit').attr('disabled', 'disabled');
      },
      success: function () {
        currentSection++;
        showSection(currentSection);
        toggleNextButton();
        $('#event-form-error-message').html('');
      },
      error: function (xhr) {
        $('#event-form-error-message').html(xhr.responseJSON.data.errors.join('. '));
        $('#form-button-submit').prop("disabled", false);
      },
    });
  });
});
